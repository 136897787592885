@import '@gov.wa.lni/framework.one-lni/source/styles/settings.scss';

.lni-c-dosh-hospitalizations__table {
    & .lni-c-table__wrapper {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}

.lni-c-dosh-hospitalizations__table-cell {
    min-width: 8rem;
}