@use "sass:math";

@import '@gov.wa.lni/framework.one-lni/source/styles/settings.scss';

.lni-c-shopping-cart {
    height: 100%;
}


.lni-c-shopping-cart__toggle {
    position: fixed;
    z-index: 1;
    right: 0;
    bottom: 8.1em;
    padding: math.div($space, 4) $space;
    line-height: 0;
    border-top-left-radius: $border-radii--small;
    border-bottom-left-radius: $border-radii--small;
    border: 2px solid white;
    border-right-width: 0;
    background-color: $background--okay;
    color: $text--inverse;
    font-weight: bold;

    @include box-shadow(2);

    &::after {
        content: '';
        position: absolute;
        z-index: -1;
        right: 20px;
        display: block;
        width: 100px;
        height: 100px;
        background-color: $background--okay;
        border-radius: 50%;
        opacity: 0;
        transform-origin: center center;

        @include shrink-in-and-fade;
    }
}

.lni-c-shopping-cart__content {
    position: fixed;
    z-index: 3;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    max-width: 600px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 4px 0 0 4px;
    background-color: $background--content;

    @include min-screen-width('sm') {
        left: auto;
    }
}

.lni-c-shopping-cart__item-title {
    flex-basis: 100%; //IE 11
}

.lni-c-input-quantity {
    width: 2.75rem;
    text-align: right;
    padding: 2px 4px;
    border: 1px solid $border--dark;
}