@import '@gov.wa.lni/framework.one-lni/source/styles/settings.scss';

.lni-c-self-insured-employers {
    .lni-u-button-reset {
        cursor: pointer;
    }
}

.lni-c-self-insured-employers-card {
    height: unset;
    margin-top: 0.5rem;

    @include min-screen-width(515px) {
        height: auto;
        min-height: 10rem;
    }
}

.lni-c-self-insured-employers-card__contact {
    @include type-setting(xxs);

    line-height: 1.3rem;

    @include min-screen-width(515px) {
        flex-direction: row;
    }
}

.lni-c-self-insured-employers-card__columns {
    @include min-screen-width(320px) {
        flex-direction: column;
    }

    @include min-screen-width(515px) {
        flex-direction: row;
    }
}

.lni-c-self-insured-employers-card__header {
    color: $self-insured-employers-card-header__text;
    font-family: $font-family--franklin;
    flex-direction: column;

    @include min-screen-width(515px) {
        flex-direction: row;
    }
}

.lni-c-self-insured-employers-card__address {
    @include min-screen-width(320px) {
        width: 18rem;
        margin-bottom: 1rem;
    }

    @include min-screen-width(515px) {
        width: 60%;
    }
}
