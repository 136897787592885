@import '@gov.wa.lni/framework.one-lni/source/styles/settings.scss';

.lni-c-overtime-exempt-budget-tool__helper-text {
    @include type-setting(xxs);

    color: $text--primary;
    padding-left: 8px; //match padding of helper text
}

.lni-c-overtime-exempt-budget-tool__note {
    @include type-setting(xxs);

    color: $text--alternate;
    padding-left: 8px; //match padding of helper text
    line-height: 1.3333rem;
}

.lni-c-overtime-exempt-budget-tool__results {
    font-weight: bold;
    text-align: left;

    @include min-screen-width(md) {
        text-align: right;
    }
}

.lni-c-overtime-exempt-budget-tool__footer {
    @include type-setting(xs);

    & p {
        max-width: 100%;
    }
}

.lni-c-overtime-exempt-budget-tool__row {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;

    @include min-screen-width(md) {
        flex-direction: row;
    }

    & .--cell-sm {
        padding-right: 2em;
        text-align: left;
        width: 100%;

        @include min-screen-width(md) {
            width: calc(100% * 3 / 12); //copy lni-o-cell--3of12
            text-align: right;
        }
    }

    & .--cell-lg {
        width: 100%;

        @include min-screen-width(md) {
            width: calc(100% * 9 / 12); //copy lni-o-cell--9of12
        }
    }
}