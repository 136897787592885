@use "sass:math";

@import '@gov.wa.lni/framework.one-lni/source/styles/settings.scss';

.lni-c-simbic-calculator__container {
    display: flex;
    flex-direction: column;
}

.lni-c-simbic-calculator__input fieldset legend {
    font-size: .75rem;
    line-height: 1rem;
}

.lni-c-input-date__wrapper {
    min-width: 20rem;
    margin-top: .375rem;
}