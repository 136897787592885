@use "sass:math";

@import '@gov.wa.lni/framework.one-lni/source/styles/settings.scss';

.lni-c-rule-search__link--reset {
    color: $rule-making__link--normalize;
    font-family: $font-family--franklin;
}

.lni-c-rule-search__container {
    flex-direction: column;

    @include min-screen-width(800px) {
        flex-direction: row;
    }
}

.lni-c-rule-search__clear {
    width: 100%;
    justify-content: center;
    display: flex;
    margin-top: math.div($space, 4);

    @include min-screen-width(800px) {
        width: auto;
        margin-top: 0;
    }
}

.lni-c-rule-search__links {
    border-bottom: 1px solid $rule-making__border;
}

.lni-c-rule-search__form {
    @include min-screen-width(md) {
        width: $space * 24;
    }
}

.lni-c-find-rules__info-icon {
    margin-top: 20px;
}

.lni-c-rule-card {
    font-family: $font-family--franklin;
}

.lni-c-rule-card__category {
    color: $rule-making-card__category--text;

    @include type-setting(xxs);
}

.lni-c-rule-card__title {
    @include type-setting(lg);
}

.lni-c-rule-card__details {
    @include type-setting(md);
}

.lni-c-rule-card__purpose {
    font-family: $font-family--roboto;
    line-height: 1.6;
}

.lni-c-rule-card__purpose-toggle {
    margin-left: math.div(-$space, 2);
}

.lni-c-rule-card__column {
    border: 1px solid $rule-making-card__column--border;
    border-right-color: transparent;
    border-left-color: transparent;
    padding: math.div($space, 2);
}

.lni-c-rule-card__column:first-child {
    flex: 1 0 100%;

    @include min-screen-width(450px) {
        flex: 1 0 100px;
        border-right-color: $rule-making-card__column--border;
    }
}

.lni-c-rule-card__column:nth-child(2) {
    flex: 1 0 100%;
    order: 2;

    @include min-screen-width(450px) {
        order: 3;
        flex: 1 0 354px;
    }

    @include min-screen-width(800px) {
        border-right-color: $rule-making-card__column--border;
        order: 2;
    }
}

.lni-c-rule-card__column:last-child {
    flex: 1 0 100%;
    order: 3;

    @include min-screen-width(450px) {
        flex: 1 0 100px;
        order: 2;
    }

    @include min-screen-width(800px) {
        order: 3;
    }
}

.lni-c-rule-card__comments,
.lni-c-rule-card__information {
    color: $rule-making-card__comments--text;
    font-family: $font-family--roboto;

    //IE11 Fix for Flex
    width: 100%;
}

.lni-c-rule-card__link {
    color: $rule-making-activity__link;
    text-decoration: none;
}

.lni-c-rule-activity {
    font-family: $font-family--roboto;

    @include type-setting(xs);
    &.--active {
        background: rgba($rule-making-activity__row--background, 0.07);
    }
}

.lni-c-rule-activity__title {
    color: $rule-making-activity__title--text;

    @include type-setting(xs);
}

.lni-c-rule-activity__link {
    color: $rule-making-activity__link;
}

.lni-c-rule-activity__icon {
    color: transparent;
    &--toggle {
        color: $rule-making-activity__icon;
        font-size: 10px;
    }
    &.--active {
        color: $rule-making-activity__icon--active;
    }
}

.lni-c-rulemaking-calendar__mini-calendar {
    height: 84px;
    width: 120px;

    & .--weekday {
        line-height: 24px;
        height: 24px;
        background: $background--dark;
        color: $text--inverse;
        text-align: center;
    }

    & .--date {
        @include type-setting(lg);

        height: 60px;
        font-weight: 800;
        font-family: $font-family--franklin;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $border--subtle;
    }
}

.lni-c-rulemaking-calendar__event {
    @include type-setting(xs);

    border-bottom: 1px solid $border--subtle;
}

.lni-c-rulemaking-calendar__button-bar {
    & .--hidden {
        visibility: hidden;
    }
}