@use "sass:math";

@import '@gov.wa.lni/framework.one-lni/source/styles/settings.scss';

.lni-c-news-and-data-center__grid {
    display: grid;
    display: -ms-grid;
    -ms-grid-rows: 100%;
    grid-gap: $space;
    grid-template-columns: 1fr;
    -ms-grid-columns: 1fr;
}

.lni-c-news-and-data-center__block:nth-child(2) {
    margin-top: $space * 2;
    -ms-grid-row: 2;
    -ms-grid-column: 1;
}


.lni-c-news-and-data-center__block:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
}


@include min-screen-width(md) {
    .lni-c-news-and-data-center__grid {
        grid-template-columns: repeat(2, 1fr);
        -ms-grid-columns: 1fr $space 1fr;
    }

    .lni-c-news-and-data-center__block:nth-child(2) {
        -ms-grid-row: 1;
        -ms-grid-column: 3;
        margin-top: 0;
    }
    .lni-c-news-and-data-center__block:nth-child(1) {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
    }
}

.lni-c-news-and-data-center__more:hover {
    color: $text--clickable;
}

.lni-c-news-and-data-center__share {
    vertical-align: middle;
    display: flex;
    justify-content: flex-end;
}

.lni-c-news-and-data-center__twitter {
    height: 400px;
}

.lni-c-news-and-data-center__link {
    text-decoration: none;
    color: $touts__link;
    font-family: $font-family--franklin;
    text-transform: uppercase;

    & .lnicon--envelope {
        @include type-setting(xl);
    }

    &:hover{
        text-decoration: underline;
    }
}

.lni-c-news-and-data-center__article-headline {
    text-decoration: none;

    &:hover{
        text-decoration: underline;
    }

    &:focus{
        outline: 2px solid $focus-ring;
    }
}

.lni-c-news-and-data-center__date {
    @include type-setting(xs);

    font-family: $font-family--franklin;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 400;
}

.lni-c-news-and-data-center__latest {
    & .lni-c-news-and-data-center__date {
        color: $text--alternate;
    }

    h3 {
        padding-bottom: 0.5rem;
    }
}

.lni-c-news-and-data-center-article {
    & .lni-c-news-and-data-center__date {
        color: $text--alternate;
    }

    & h3 {
        max-width: 24.5rem;
    }

    & p {
        max-width: 100%;
    }
}

.lni-c-news-and-data-center-media-contacts {
    p {
        max-width: 100%;
    }
}