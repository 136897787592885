@import '@gov.wa.lni/framework.one-lni/source/styles/settings.scss';

th.lni-c-vendor-services-lookup_table-cell div,
td.lni-c-vendor-services-lookup_table-cell div {
    @include type-setting(xxs);

    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    min-width: 100px;
    max-width: 200px;
}