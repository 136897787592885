@import '@gov.wa.lni/framework.one-lni/source/styles/settings.scss';

.lni-c-ship-grant-projects-card__details > * {
    flex-basis: 33.3%;
    padding-inline: 4px;

    @include min-screen-width(sm) {
        padding-inline: calc(#{$space}/2);
    }
}

.lni-c-ship-grant-projects-card {
    border: 1px solid $ship-grant-projects-card__border;
}

.lni-c-ship-grant-projects-card__details,
.lni-c-ship-grant-projects-card__summary,
.lni-c-ship-grant-projects-card__products {
    border-top: 1px solid $ship-grant-projects-card__border;
}

.lni-c-ship-grant-projects-card__amount {
    border-right: 1px solid $ship-grant-projects-card__border;
}

.lni-c-ship-grant-projects-card__project-id {
    border-left: 1px solid $ship-grant-projects-card__border;
}

.lni-c-ship-grant-projects-card__summary,
.lni-c-ship-grant-projects-card__products {
    max-width: 50rem;
}