@use "sass:math";

@import '@gov.wa.lni/framework.one-lni/source/styles/settings';

.lni-c-button--no-padding.--text {
    padding: 0;
}

.lni-c-search-shrl__select {
    max-width: 100%;
    width: 100%;

    @include min-screen-width(450px) {
        max-width: 30%;
    }
}

.lni-c-shrl-card__column1 {
    flex: 1 1 auto;
}

.lni-c-quicklink-shrl__bigLetter
    {
	    border:$border--base;
        font-weight: bold;
        font-size: 120%;
        padding: 2px 5px;
        margin-right: 10px;
        color: White;
        background-color:$background--highlight;
    }

tr:nth-child(odd)
    {
        background-color:$background--alternate;
    }

td
    {
        width: 1000px;
    }