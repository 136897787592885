@import '@gov.wa.lni/framework.one-lni/source/styles/settings';

.lni-c-glossary__heading {
    width: 3em;
    background-color: $background--info;
    color: $text--inverse;
}

.lni-c-glossary__definition {
    & .--highlight {
        background-color: $info--background;
    }

    dt::after {
        content: "\00a0";
    }
    dd::before {
        content: "-\00a0";
    }
}