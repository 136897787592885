@import '@gov.wa.lni/framework.one-lni/source/styles/settings.scss';

.lni-c-order-paper {
    max-width: $space * 16;
}

.lni-c-order-paper__cart {
    max-width: $reading-line-width;
}

.lni-c-order-paper__cart-item-quantity {
    width: $space * 1.5;
    flex-shrink: 0;
}

.lni-c-order-confirmation__notifier {
    background-color: $order-confirm-notification__background;

    @include type-setting(lg);

    &--fail {
        background-color: $order-confirm-notification__background--fail;
        color: $order-confirm-notification__text--fail;
    }
}

.lni-c-order-confirmation__content {
    border-bottom: 4px solid $order-confirm-header-border__background;
}

.lni-c-order-confirmation__information {
    flex: 1 1 40%;
}

.lni-c-order-confirmation__comments {
    flex: 1 1 60%;
}

.lni-c-order-confirmation__grid1 {
    flex: 0 1 60%;
}

.lni-c-order-confirmation__grid2 {
    flex: 0 1 25%;
}

.lni-c-order-confirmation__grid3 {
    flex: 0 1 15%;
}

.lni-c-order-confirmation__row {
    border-bottom: 1px solid $order-confirm-border__background;
    &:first-child {
        border-bottom: 2px solid $order-confirm-header-border__background;
    }
}