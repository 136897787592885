@use "sass:math";

@import '@gov.wa.lni/framework.one-lni/source/styles/settings.scss';

.lni-c-preferred-worker-calculator {
    svg {
        width: 32px;
        height: 8px;
        padding: 0;
        display: block;
    }
}

.lni-c-preferred-worker-calculator__grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    display: -ms-grid;
    -ms-grid-columns: 1fr;
    -ms-grid-rows: auto;
}

@for $i from 1 through 5 {
    .lni-c-preferred-worker-calculator__block:nth-child(#{$i}) {
        grid-row: $i;
        grid-column: 1;
        justify-self: center;
        align-self: center;
        -ms-grid-row: $i;
        -ms-grid-column: 1;
        -ms-grid-column-align: center;
    }
}

@include min-screen-width(md) {
    .lni-c-preferred-worker-calculator__grid {
        grid-template-columns: repeat(3, 1fr) 10% repeat(3, 1fr);
        -ms-grid-columns: 1fr 1fr 1fr 10% 1fr 1fr 1fr;
    }

    @for $i from 1 through 5 {
        .lni-c-preferred-worker-calculator__block:nth-child(#{$i}) {
            @if $i % 2 == 0 {
                grid-row: math.div($i, 2);
                grid-column: 4;
                -ms-grid-row: math.div($i, 2);
                -ms-grid-column: 4;
                -ms-grid-row-align: center;
            }

 @else {
                @if $i == 1 {
                    grid-row: 1;
                    grid-column: 1 / span 3;
                    justify-self: end;
                    -ms-grid-row: 1;
                    -ms-grid-column: 1;
                    -ms-grid-column-span: 3;
                    -ms-grid-row-align: end;
                    -ms-grid-column-align: center;
                }

 @else if $i == 3 {
                    grid-row: 1;
                    grid-column: 5 / span 3;
                    justify-self: start;
                    -ms-grid-row: 1;
                    -ms-grid-column: 5;
                    -ms-grid-column-span: 3;
                    -ms-grid-row-align: start;
                    -ms-grid-column-align: start;
                }

 @else {
                    grid-row: 3;
                    grid-column: 3 / span 3;
                    -ms-grid-row: 3;
                    -ms-grid-column: 3;
                    -ms-grid-column-span: 3;
                    -ms-grid-column-align: stretch;
                }
            }
        }
    }
}

.lni-c-preferred-worker-calculator__info {
    @include type-setting(md);

    font-weight: 400;
    font-family: $font-family--franklin;
}

.lni-c-preferred-worker-calculator__note {
    @include type-setting(xs);

    color: $text--alternate;
}

.lni-c-preferred-worker-calculator__card {
    @include box-shadow;

    background-color: transparent;
    border-radius: 4px;
    width: 260px;
    padding: 1.3333rem 1rem;
    margin: 0 1.3333rem;
}

.lni-c-preferred-worker-calculator__card--header {
    @include type-setting(xs);

    font-family: $font-family--franklin;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 400;
}

.lni-c-preferred-worker-calculator__card--currency {
    @include type-setting(lg);

    font-family: $font-family--franklin;
    font-weight: 900;
    text-align: right;
}