@import '@gov.wa.lni/framework.one-lni/source/styles/settings.scss';

$month-hover-background-color: map-get($palette, cornflower-blue);

.lni-c-date-filter__date {
    height: 72px;
    width: 72px;
    padding: 4px;
    border: 1px solid $border--subtle;
    border-radius: 3px;

    & > * {
        text-align: center;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.lni-c-date-filter__date-year {
    background: $background--dark;
    color: $text--inverse;
    font-weight: bold;
}

.lni-c-date-filter__date-month {
    border: 0;

    &:hover {
        background-color: $month-hover-background-color;
        color: $text--alternate;
    }


    &.--active {
        background: $button__background;
        color: $text--inverse;
    }
}