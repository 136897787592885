@import '@gov.wa.lni/framework.one-lni/source/styles/settings.scss';

.lni-c-explanation-of-benefits-lookup_table-cell {
    @include type-setting(xxs);

    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 100px;
    max-width: 200px;
    &.lni-c-explanation-of-benefits-lookup_description {
        width: 400px;
        overflow: hidden;
    }
}
