@use "sass:math";

@import '@gov.wa.lni/framework.one-lni/source/styles/settings.scss';

.lni-c-locations {
    display: grid;
    display: -ms-grid;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto math.div($space, 2) auto math.div($space, 2) auto math.div($space, 2) auto;
    -ms-grid-columns: 100%;
    grid-gap: $space;
    line-height: 2;
    padding: $space 0;
}

@for $i from 1 through 4 {
    .lni-c-locations-child:nth-child(#{$i}) {
        -ms-grid-row: #{2 * $i - 1};
        -ms-grid-column: 1;
    }
}

@include min-screen-width(581px) {
    .lni-c-locations {
        grid-template-columns: repeat(2, 1fr);
        -ms-grid-columns: 1fr math.div($space, 2) 1fr;
        -ms-grid-rows: auto math.div($space, 2) auto;
    }

    .lni-c-locations-child:nth-child(3):last-child {
        grid-column-start: 1;
        grid-column-end: 3;
        -ms-grid-row: 3;
        -ms-grid-column: 1;
        -ms-grid-column-span: 3;
    }

    .lni-c-locations-child:nth-child(2) {
        -ms-grid-row: 1;
        -ms-grid-column: 3;
    }

    .lni-c-locations-child:nth-child(3) {
        -ms-grid-row: 3;
        -ms-grid-column: 1;
    }


    .lni-c-locations-child:last-child {
        -ms-grid-row: 1;
        -ms-grid-column: 3;
    }
}