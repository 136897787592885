@use "sass:math";

@import '@gov.wa.lni/framework.one-lni/source/styles/settings.scss';

.lni-c-search-sharp__input {
    max-width: 28rem;
    width: 100%;
}

.lni-c-search-sharp__select {
    max-width: 100%;
    width: 100%;

    @include min-screen-width(450px) {
        max-width: 30%;
    }
}

.lni-c-search-sharp__multi-select {
    max-width: 100%;

    @include min-screen-width(450px) {
        max-width: 70%;
    }
}

.lni-c-search-sharp__multi-select--year {
    max-width: 100%;

    @include min-screen-width(450px) {
        max-width: 30%;
        margin-right: 5%;
    }
}

.lni-c-search-sharp__submit {
    width: 100%;

    @include min-screen-width(450px) {
        width: auto;
    }
}

.lni-c-search-sharp__button-bar {
    margin-left: math.div(-$space, 2);
    width: calc(100% + 1.6667rem);
}

.lni-c-sharp-card__title {
    color: $sharp-card-link__text;
    font-family: $font-family--franklin;
    text-decoration: none;
}

.lni-c-sharp-card__icon {
    text-decoration: none;

    @include type-setting(xl);
}

.lni-c-sharp-card__column1 {
    flex: 1 1 auto;
}

.lni-c-sharp-card__column2 {
    flex: 1 1 auto;
    margin-top: math.div($space, 2);
    text-align: left;
}

.lni-c-sharp-card__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: $space;
}

.lni-c-sharp-card__publication {
    margin-top: math.div($space, 2);
}

@include min-screen-width(650px) {
    .lni-c-sharp-card__icon {
        display: flex;
    }

    .lni-c-sharp-card__info {
        flex-direction: row;
    }

    .lni-c-sharp-card__publication {
        display: flex;
        justify-content: space-between;
    }

    .lni-c-sharp-card__column1 {
        flex: 0 1 65%;
    }
    .lni-c-sharp-card__column2 {
        flex: 1 1 30%;
        text-align: right;
    }
}