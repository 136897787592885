@import '@gov.wa.lni/framework.one-lni/source/styles/settings.scss';

.lni-c-safety-topic__index > div,
.lni-c-safety-topic__index > a {
    flex: 1 0 48%;
    min-width: 250px;
}

.lni-c-safety-topic__left-nav-string {
    display: none;

    @include min-screen-width(430px) { // Must match isMobile computed property
        display: inline;
    }
}

.lni-c-search-sharp__button-bar {
    display: block;

    @include min-screen-width(430px) { // Must match isMobile computed property
        display: none;
    }
}

.lni-c-safety-topic-card__channel {
    color: $text--alternate;
    letter-spacing: 1.5px;
}

.lni-c-safety-topic-card h2 {
    margin-top: 0;
}