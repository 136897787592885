@use "sass:math";

@import '@gov.wa.lni/framework.one-lni/source/styles/settings.scss';

.lni-c-condition-treatment__box {
    border-radius: 3px;
    padding: 15px;
    background-color: $background--content;

    @include box-shadow(1);
}

.lni-c-condition-treatment__headers {
    flex-wrap: wrap;

    @include type-setting(xxs);

    @include min-screen-width(450px) {
        flex-wrap: nowrap;
    }
}

.lni-c-condition-treatment__title {
    color: $text--primary;
    font-family: $font-family--franklin;

    @include type-setting(xl);
}

.lni-c-condition-treatment__authorization {
    color: $text--alternate;
    font-weight: 700;

    @include type-setting(sm);
}

.lni-c-condition-treatment__methods {
    font-family: $font-family--franklin;
    font-weight: 400;

    @include type-setting(xxs);
}
