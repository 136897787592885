
@import '@gov.wa.lni/framework.one-lni/source/styles/settings.scss';

.lni-c-calculation-display__row-wrapper {
    display: flex;
    flex-direction: column;

    @include min-screen-width(400px) {
        flex-direction: row;
        margin-right: 1rem;
    }
}

.lni-c-input-display__table-content-table {
    width: 100%;
    border: none;
}

.lni-c-calculation-display__mt-5 {
    margin-top: 5px;
}

.lni-c-calculation-display__pt-5 {
    padding-top: 1.5rem;
}

.lni-c-calculation-display__border-bottom {
    border-bottom: $calculator-display__underline solid 1px;
}

.lni-c-calculation-display__input-display {
    @include box-shadow;

    background-color: transparent;
    border-radius: 4px;
    padding: 0.8334rem 0;
}

.lni-c-calculation-display__input-display,
.lni-c-calculation-display__card_width {
    max-width: 16rem;
    width: 100%;
}

.lni-c-calculation-display__method {
    padding: 0;
}

.lni-c-calculation-display-methods .lni-c-calculation-display__card_width td,
.lni-c-calculation-display-methods .lni-c-calculation-display__card_width h3 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.--evaluation {
    width: 80%;
    border-top: 2px solid $calculator-display__underline;
}

.lni-c-calculation-display__result-output {
    margin-right: 5px;
}

.lni-c-calculation-display__break-line {
    width: 100%;
    border-top: 2px solid $border--base;
}

.lni-c-calculation-display__calculation__row {
    display: flex;
    margin-top: 0.5rem;
}

.lni-c-calculation-display__calculation__operation {
    display: inline-block;
    width: 2rem;
    flex-shrink: 0;
    text-align: center;
    font-weight: bold;
}

.c-calculation output {
    display: inline-block;
    width: 4rem;
    margin-right: 0.5em;
    text-align: right;
    flex-shrink: 0;
}

.calculation label {
    flex-grow: 0;
}

.lni-c-calculation-display__label {
    color: $text--primary;
}

@media print {
    :root .lni-c-content__wrapper.lni-c-content__wrapper {
        margin-top: 0;
    }

    .lni-c-calculation-display__table-wrapper {
        flex-direction: row;
    }

    :root .lni-c-calculation-display__row-wrapper.lni-c-calculation-display__row-wrapper {
        flex-direction: row;
        margin-top: 0;
    }

    :root .lni-c-calculation-display__input-display.lni-c-calculation-display__input-display {
        margin: 0;
        padding: 0;
    }

    :root .lni-c-calculation-display__input-display.lni-c-calculation-display__input-display * {
        padding-bottom: 0;
        padding-top: 0;
    }

    :root .lni-c-calculation-display__input-display.lni-c-calculation-display__input-display h3 {
        padding-top: 0;
        margin-top: 0;
    }

    :root .lni-c-calculation-display__input-display .lni-c-calculation-display__result-output {
        margin-right: 5px;
    }


    :root .lni-c-calculation-display__label {
        line-height: 1.667;
    }
}