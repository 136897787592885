@import '@gov.wa.lni/framework.one-lni/source/styles/settings.scss';

.lni-c-third-party-admins {
    .lni-u-button-reset {
        cursor: pointer;
    }
}

.lni-c-third-party-admins-card {
    height: unset;
    margin-top: 0.5rem;

    @include min-screen-width(515px) {
        height: auto;
        min-height: auto;
    }
}

.lni-c-third-party-admins__contact {
    @include type-setting(xxs);

    line-height: 1.3rem;

    @include min-screen-width(515px) {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }
}

.lni-c-third-party-admins-card__columns {
    @include min-screen-width(320px) {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    @include min-screen-width(515px) {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }
}

.lni-c-third-party-admins-card__header {
    color: $self-insured-employers-card-header__text;
    font-family: $font-family--franklin;
    flex-direction: column;

    @include min-screen-width(515px) {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }
}

.lni-c-third-party-admins-card__address {
    @include min-screen-width(320px) {
        width: 18rem;
    }

    @include min-screen-width(515px) {
        width: 60%;
    }
}
