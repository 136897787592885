@import '@gov.wa.lni/framework.one-lni/source/styles/settings.scss';

.lni-ergonomic-ideas--icon {
    color: $text--primary;
}

.lni-ergonomic-ideas__inputs {
    width: 100%;

    @include min-screen-width(sm) {
        width: 500px;
    }
}

.lni-ergonomic-ideas__results {
    border-top: 1px solid rgb(93 95 111 / 50%);
}