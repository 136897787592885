/* Staggers pulse-dot animation around circle for spinner */
@mixin pulse-around($count: 1) {
    $speed: 1.1;

    animation-name: pulse-dot;
    animation-duration: #{$speed}s;
    animation-iteration-count: infinite;

    /* Animation for pulsing dots on spinner */
    @keyframes pulse-dot {
        /* 100%/8 dots */
        12.5% {
            transform: scale(2.5);
        }
    }

    @for $i from 1 through $count {
        $delay: $i * $speed * 0.125 - 0.5;

        &:nth-child(#{$i}) {
            animation-delay: #{$delay}s;
        }
    }
}

@mixin grow-out-and-fade {
    @keyframes grow-out-and-fade {
        0% {
            opacity: 1;
            transform: scale(0);
        }
        30% {
            transform: scale(2);
        }
        100% {
            opacity: 0;
            transform: scale(2);
        }
    }

    animation-name: grow-out-and-fade;
    animation-duration: 1s;
}

@mixin shrink-in-and-fade {
    @keyframes shrink-in-and-fade {
        0% {
            opacity: 0;
            transform: scale(3);
        }
        30% {
            opacity: 0.9;
        }
        100% {
            transform: scale(0);
        }
    }

    animation-name: shrink-in-and-fade;
    animation-duration: 1s;
}