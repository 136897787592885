@import '@gov.wa.lni/framework.one-lni/source/styles/settings.scss';

.lni-c-forms-and-pubs-search__language {
    .--id {
        display: block;
    }

    @include min-screen-width(sm) {
        .--id {
            display: inline-flex;
        }
    }
}