
@use "sass:math";

@import '@gov.wa.lni/framework.one-lni/source/styles/settings';

.lni-c-system-status {
    background-color: $background--content;
    border-radius: $border-radii--small;
    color: $text--primary;
    margin: math.div($space, 2) 0;
    padding: math.div($space, 4) 0;
    word-break: break-word; /* stop long words from overflowing */
    width: 100%; /* IE11 Hack for flexbox */
}

.lni-c-system-status-icon {
    @include min-screen-width(400px) {
        font-size: 3rem;
        line-height: 1;
        padding-top: 10px; //visually align
    }

    margin-right: math.div($space, 2);

    .--warn & {
        color: $background--warn;
    }
    .--info & {
        color: $background--info;
    }
    .--okay & {
        border: solid 2px $background--okay;
        border-radius: 50%;
        color: $text--success;
        font-size: 0.6em;
        height: 18px;
        line-height: 1.4;
        margin-top: 5px;
        min-width: 18px;
        text-align: center;
        width: 18px;

        @include min-screen-width(400px) {
            border-width: 5px;
            font-size: 1.6em;
            height: 54px;
            line-height: initial;
            margin-top: 10px;
            min-width: 54px;
            padding-top: 8px;
            text-align: center;
            width: 54px;
        }
    }
}