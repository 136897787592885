@import '@gov.wa.lni/framework.one-lni/source/styles/settings.scss';

.lni-c-logger-training-lookup--card {
    .--category {
        @include type-setting(xxs);

        color: $text--alternate;
        font-family: $font-family--franklin;
    }

    .--format {
        @include type-setting(xl);

        color: $text--alternate;
    }

    .--title {
        @include type-setting(md);

        font-family: $font-family--franklin;
        font-weight: bold;
    }

    .--desc {
        @include type-setting(xs);

        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2; // Number of lines to show
        -webkit-box-orient: vertical;
    }
}