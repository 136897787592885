
.flow>*+* {
    margin-top: 1.5rem;
}

.lni-c-safety-retaliation-complaint legend {
    font-weight: bold;
    font-size: 1.5rem;
}

.lni-c-safety-retaliation-complaint .lni-c-radio__legend,
.lni-c-input-date__wrapper legend {
    font-weight: normal;
    font-size: 1.25rem;
}
