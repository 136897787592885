@use "sass:math";

@import '@gov.wa.lni/framework.one-lni/source/styles/settings.scss';

.lni-c-lep-calculator__form {
    display: flex;
    flex-direction: column;

    @include min-screen-width(400px) {
        justify-items: flex-end;
    }
}

.lni-c-lep-calculator__input-container {
    display: flex;
    flex-direction: column;

    @include min-screen-width(400px) {
        flex-direction: row;
    }
}

.lni-c-calculation-display__table-wrapper {
    display: flex;
    flex-direction: column;

    @include min-screen-width(400px) {
        flex-direction: row;
    }
}

.lni-c-lep-calculator__input {
    max-width: 16rem;
}

.test-lep_from-date-wrapper {
    margin-top: 0;
}


.lni-c-lep-calculator__buttons-wrapper {
    @include min-screen-width(600px) {
        max-width: 35rem;
        flex-direction: row;
    }

    flex-direction: column;
}

.lni-c-lep-calculator__buttons-wrapper button {
    max-width: 300px;
}

.lni-c-lep-calculator__submit {
    @include min-screen-width(600px) {
        margin-left: 0;
    }

    @include min-screen-width(400px) {
        margin-left: math.div($space, 2);
        margin-top: 0;
    }

    margin-left: 0.83rem;
}

.lni-c-lep-calculator__result {
    color: $text--alternate;
    font-family: $font-family--franklin;

    @include type-setting(lg);
}


.lni-c-lep-calculator_print-inputs-container {
    display: none;
}

@media print {
    .lni-c-content__wrapper {
        margin-top: 0;
        margin-bottom: 0;
    }
    .lni-c-breadcrumbs__container {
        display: none;
    }
    .lni-c-box--content {
        padding-top: 0;
        padding-bottom: 0;
    }
    .lni-c-lep-calculator__form {
        flex-direction: row;
        line-height: 1.2;
    }

    .lni-c-lep-calculator_print-inputs-container {
        display: block;
        width: 80%;
    }

    .lni-c-lep-calculator_print-inputs-wages-line {
        display: flex;
        justify-content: space-between;
    }

    .lni-c-lep-calculator_print-inputs-line {
        display: flex;
        justify-content: flex-start;
        .lni-c-lep-calculator_print-inputs-left-column {
            width: 350px;
        }
    }

    .lni-c-lep-calculator__inputs-container {
        display: none;
    }

    .lni-c-lep-calculator__buttons-wrapper {
        display: none;
    }
}